import React from 'react'
import { Link } from 'gatsby'
import { useFooterNavigationList } from '../../custom-query-hooks/useFooterNavigationList'
import { useServicesList } from '../../custom-query-hooks/useServicesList'

/* assets */
import leftBracket from '../../assets/images/left-bracket.svg'
import rightBracket from '../../assets/images/right-bracket.svg'

const FooterLinks = () => {
  const serviceLinks = useServicesList()
  const pageLinks = useFooterNavigationList()

  return (
    <div className='flex flex-col justify-center text-white p-4 xl:p-0 text-lg'>
      <div className='flex align-middle items-center'>
        <div className='w-24 font-bold flex-grow-0'>Our Services</div>
        <img className='flex flex-grow-0 flex-shrink-0 h-24 text-white' src={leftBracket} alt='Open Bracket' />
        <div className='flex-grow text-center'>
          {serviceLinks.map((service, index) => {
            return (
              <div key={index}>
                <Link to={service.pageLink} activeClassName='text-primary' className='underline'>
                  {service.relatedService.name}
                </Link>
              </div>
            )
          })}
        </div>
        <img className='flex flex-grow-0 flex-shrink-0 h-24 text-white' src={rightBracket} alt='Close Bracket' />
      </div>
      <div className={`grid grid-flow-row-dense grid-rows-${Math.ceil(pageLinks.length / 2)} grid-cols-2 pt-12`}>
        {pageLinks.map((page, index) => {
          const { name, pageLink } = page
          return (
            <div key={index} className={index + 1 > Math.ceil(pageLinks.length / 2) ? 'col-start-2 text-right' : 'col-start-1'}>
              <Link to={pageLink} activeClassName='text-primary' className='underline'>
                {name}
              </Link>
            </div>
          )
        })}
        {pageLinks.length % 2 !== 0 && <div className='col-start-2 text-right'></div>}
      </div>
    </div>
  )
}

export default FooterLinks
