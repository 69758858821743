import { graphql, useStaticQuery } from 'gatsby'

export const useFooterNavigationList = () => {
  const { contentfulNavigation } = useStaticQuery(
    graphql`
      query {
        contentfulNavigation(location: { eq: "Footer" }) {
          navigationItem {
            name
            pageLink
          }
        }
      }
    `
  )
  return contentfulNavigation.navigationItem
}
