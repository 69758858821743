import React from 'react'
import { Link } from 'gatsby'

/* assets */
import logo from '../../assets/images/wdc-logo-with-white-icon.svg'

const WdcButton = ({
  buttonLink,
  buttonText,
  buttonType,
  className,
  showIcon,
}) => {
  return (
    <>
      {buttonType ? (
        <button
          type={buttonType}
          name={buttonType.toLowerCase()}
          className={`wdc-button ${className}`}
        >
          {buttonText}
        </button>
      ) : (
        <Link to={buttonLink} className={`wdc-button hover:text-gray-50 ${className}`} role='button'>
          {showIcon && (
            <img
              src={logo}
              alt=''
              className='hidden md:block h-5 pr-1.5 -ml-0.5'
            />
          )}
          <span>{buttonText}</span>
        </Link>
      )}
    </>
  )
}

WdcButton.defaultProps = {
  buttonLink: '/',
  buttonText: 'Enquire Now',
  buttonType: false,
  className: '',
  showIcon: false,
}

export default WdcButton
