import React from 'react'
import FooterCompanyInfo from '../elements/FooterCompanyInfo'
import FooterForm from '../elements/FooterForm'
import FooterLinks from '../elements/FooterLinks'
import FooterSocialIcons from '../elements/FooterSocialIcons'

const Footer = () => {
  return (
    <footer className="mt-8 bg-secondary-dark py-4">
      <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 grid-rows-4 md:grid-rows-2 lg:grid-rows-2 xl:grid-rows-1 gap-2 xl:gap-y-0 p-1 grid-flow-row-dense">
        <div className="flex flex-col gap-y-4 px-4 xl:px-0 justify-center row-start-4 md:row-start-2 xl:row-start-1">
          <FooterCompanyInfo />
        </div>
        <div className="flex flex-col justify-center text-center text-gray-50 row-start-3 md:row-start-2 xl:row-start-1">
          <FooterSocialIcons />
        </div>
        <div className="flex flex-col justify-center text-center lg:col-span-2 lg:row-span-2">
          <FooterForm />
        </div>
        <div className="lg:row-span-1 lg:col-span-2 flex flex-col justify-center p-4 xl:pr-0">
          <FooterLinks />
        </div>
      </div>
    </footer>
  )
}

export default Footer
