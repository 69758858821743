import React from 'react'

/* assets */
import logo from '../../assets/images/we-do-code-logo-white.svg'

const FooterCompanyInfo = () => {
  return (
    <>
      <img
        src={logo}
        alt="We Do Code Logo"
        className="mx-auto md:mx-0 w-32 sm:w-16 md:w-32"
      />
      <p className="font-mont text-center sm:text-left text-gray-50 tracking-wider">
        151 The Rock
        <br />
        Bury
        <br />
        Greater Manchester
        <br />
        BL9 6PZ
      </p>
      <p className="font-mont text-center md:text-left text-gray-50 tracking-wider pb-0">
        0161 50 12345
        <br />
        <span className="text-sm">&#115;&#097;&#108;&#101;&#115;&#064;&#119;&#101;&#100;&#111;&#099;&#111;&#100;&#101;&#046;&#099;&#111;&#046;&#117;&#107;</span>
      </p>
    </>
  )
}

export default FooterCompanyInfo
