import React, { useState } from 'react'
import { FaBars } from 'react-icons/fa'
import { Link } from 'gatsby'
import { useNavigationList } from '../../custom-query-hooks/useNavigationList'
import WdcButton from '../elements/WdcButton'

/* assets */
import logo from '../../assets/images/we-do-code-logo.svg'

const Navbar = ({ showShadow, showProgress }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [pageProgress, setPageProgress] = useState(0)
  const pageLinks = useNavigationList()

  if (typeof window !== 'undefined') {
    const totalHeight = document.body.scrollHeight - window.innerHeight
    window.onscroll = function () {
      setPageProgress((window.pageYOffset / totalHeight) * 100)
    }
  }

  return (
    <nav
      className={`py-0 px-0 fixed w-screen bg-white top-0 z-30 transition-all duration-300 linear ${
        showShadow ? 'shadow-md' : 'border-b border-gray-200'
      } ${isOpen ? 'h-screen' : 'h-navbar md:h-navbarDesktop'}`}
    >
      <div className='container p-2 lg:flex lg:items-center lg:justify-between bg-white'>
        <div className='flex justify-between items-center p-2'>
          <Link to='/'>
            <img src={logo} alt='We Do Code logo' className='w-16 md:w-24' />
          </Link>
          <FaBars
            className={`text-3xl block lg:hidden text-secondary transition-all duration-300 ease-in-out ${isOpen && 'transform rotate-90'}`}
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
        <div
          className={`flex flex-col transition-all duration-600 linear overflow-hidden h-0 lg:h-auto lg:flex-row lg:items-center w-0 md:w-auto font-spar ${
            isOpen && 'h-full w-full pt-2'
          }`}
        >
          {pageLinks.map(({ name, pageLink, index }) => {
            return (
              <>
                {pageLink !== '/contact-us' ? (
                  <Link
                    key={index}
                    to={pageLink}
                    title={name}
                    className='block text-center text-2xl capitalize tracking-wide py-4 px-0 transition-all duration-600 ease-in-out navbar-styling lg:p-0 lg:border-t-0 lg:mr-5 lg:text-base whitespace-nowrap'
                    activeClassName='navbar-styling-active'
                    onClick={() => setIsOpen(false)}
                  >
                    {name}
                  </Link>
                ) : (
                  <WdcButton
                    key={index}
                    buttonText={name}
                    buttonLink={pageLink}
                    className='text-2xl mx-auto mt-4 lg:mt-0 lg:text-base lg:animate-pulse'
                  />
                )}
              </>
            )
          })}
        </div>
      </div>
      {showProgress ? <div id='pageProgress' className='h-1 bg-primary' style={{ width: `${pageProgress}%` }}></div> : <div className='h-1'></div>}
    </nav>
  )
}

export default Navbar
