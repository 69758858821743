import { graphql, useStaticQuery } from 'gatsby'

export const useServicesList = () => {
  const { allContentfulPage } = useStaticQuery(
    graphql`
      query($ne: String = null) {
        allContentfulPage(
          filter: { relatedService: { overview: { overview: { ne: $ne } } } }
          sort: { fields: relatedService___displayOrder }
        ) {
          nodes {
            pageLink
            relatedService {
              overview {
                overview
              }
              name
            }
            hero {
              gatsbyImageData
            }
          }
        }
      }
    `
  )
  return allContentfulPage.nodes
}
