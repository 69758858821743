import React from 'react'
import { FaFacebookSquare, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { useSiteMetadata } from '../../custom-query-hooks/useSiteMetadata'

const FooterCompanyInfo = () => {
  const {
    fbUrl: metaFbUrl,
    twitterUrl: metaTwitterUrl,
    linkedInUrl: metaLinkedInUrl,
  } = useSiteMetadata()

  return (
    <div>
      <p className='text-white'>Follow We Do Code</p>
      <div className='w-4/5 mx-auto'>
        <div className='grid grid-cols-2 grid-rows-2'>
          <div className='flex flex-1 justify-end'>
            <a
              href={metaLinkedInUrl}
              title='We Do Code on LinkedIn'
              target='_blank'
              rel='noreferrer'
            >
              <FaLinkedin size='64' className='m-2' alt='LinkedIn Icon' />
            </a>
          </div>
          <div className='flex flex-1 justify-start'>
            <a
              href={metaFbUrl}
              title='We Do Code on Facebook'
              target='_blank'
              rel='noreferrer'
            >
              <FaFacebookSquare size='64' className='m-2' alt='Facebook Icon' />
            </a>
          </div>
          <div className='col-span-2 flex flex-1 justify-center'>
            <a
              href={metaTwitterUrl}
              title='We Do Code on Twitter'
              target='_blank'
              rel='noreferrer'
            >
              <FaTwitter size='64' className='m-2' alt='Twitter Icon' />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterCompanyInfo
