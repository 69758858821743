import React from 'react'
import { Helmet } from 'react-helmet'
import { useSiteMetadata } from '../custom-query-hooks/useSiteMetadata'

const Seo = ({ title, description, keywords }) => {
  const {
    title: metadataTitle,
    description: metadataDescription,
    keywords: metadataKeywords,
    twitterUsername: metadataTwitterUsername,
  } = useSiteMetadata()
  const metaTitle = title ? `${title} | ${metadataTitle}` : metadataTitle
  const metaDescription = description || metadataDescription
  const metaKeywords = keywords || metadataKeywords

  return (
    <Helmet htmlAttributes={{ lang: 'en' }} title={metaTitle}>
      <meta name='description' content={metaDescription} />
      <meta name='keywords' content={metaKeywords} />
      {/*<meta name="image" content={metadata.image} />*/}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:creator' content={metadataTwitterUsername} />
      <meta name='twitter:site' content={metadataTwitterUsername} />
      <meta name='twitter:title' content={metaTitle} />
      <meta name='twitter:description' content={metaDescription} />
      {/*<meta name="twitter:image" content={`${metadata.siteUrl}${metadata.image}`}/>*/}
    </Helmet>
  )
}

export default Seo
