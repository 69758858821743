import React from 'react'
import { ImSpinner2 } from 'react-icons/im'
import useFormValidation from '../../custom-hooks/useFormValidation'
import WdcButton from './WdcButton'

const FooterForm = () => {
  const formInputs = { namejfkdd: '', emailjfkdd: '', messagejfkdd: '' }
  const requiredInputs = {
    namejfkdd: { validateOn: ['namejfkdd'] },
    emailjfkdd: { validateOn: ['emailjfkdd'] },
    messagejfkdd: { validateOn: ['messagejfkdd'] },
  }
  const {
    errors,
    handleChange,
    handleSubmit,
    inputs,
    isLoading,
    showSuccessMessage,
  } = useFormValidation(formInputs, requiredInputs)

  return (
    <div>
      <div className={isLoading ? 'flex flex-col items-center' : 'hidden'}>
        <h4 className='text-gray-50'>Sending...</h4>
        <ImSpinner2 className='animate-spin text-3xl text-white mt-4' />
      </div>
      <div className={!showSuccessMessage && 'hidden'}>
        <h4 className='text-gray-50'>
          Your message has been sent. We will reply as soon as possible. Thanks
          for getting in touch!
        </h4>
      </div>
      <form
        className={
          showSuccessMessage || isLoading
            ? 'hidden'
            : 'flex flex-col items-center justify-evenly w-11/12 mx-auto'
        }
        onSubmit={handleSubmit}
      >
        <h4 className='text-gray-50'>Get In Touch</h4>
        <div>
          <p
            className={`text-xs mb-0 pb-0 ${
              !Object.keys(errors).length ? 'text-secondary' : 'text-gray-50'
            }`}
          >
            {!Object.keys(errors).length ? '\u00A0' : 'Please fill in all fields'}
          </p>
        </div>
        <label htmlFor='footer-namejfkdd' className='sr-only text-white'>
          name
        </label>
        <input
          className={errors.namejfkdd ? 'input-field red-border' : 'input-field'}
          type='text'
          placeholder='Name'
          name='namejfkdd'
          value={inputs.namejfkdd}
          onChange={handleChange}
          id='footer-namejfkdd'
          minlength='3'
        />
        <label htmlFor='footer-emailjfkdd' className='sr-only text-white'>
          email
        </label>
        <input
          className={errors.emailjfkdd ? 'input-field red-border' : 'input-field'}
          type='email'
          placeholder='Email'
          name='emailjfkdd'
          value={inputs.emailjfkdd}
          onChange={handleChange}
          id='footer-emailjfkdd'
        />
        <label htmlFor='footer-messagejfkdd' className='sr-only text-white'>
          message
        </label>
        <textarea
          className={
            errors.messagejfkdd
              ? 'input-field h-28 lg:h-56 xl:h-28 red-border'
              : 'input-field h-28 lg:h-56 xl:h-28'
          }
          placeholder='Message'
          name='messagejfkdd'
          value={inputs.messagejfkdd}
          onChange={handleChange}
          id='footer-messagejfkdd'
        />
        <div className='absolute top-0 left-0 right-0 bottom-0 w-0 opacity-0 z-0'>
          <label htmlFor='footer-name' className='sr-only text-white'>name</label>
          <input autoComplete='off' type='text' id='footer-name' name='name' placeholder='Your name here' />
          <label htmlFor='footer-email' className='sr-only text-white'>email</label>
          <input autoComplete='off' type='email' id='footer-email' name='email' placeholder='Your email here' />
          <label htmlFor='footer-message' className='sr-only text-white'>message</label>
          <textarea autoComplete='off' placeholder='Message' name='message' id='footer-message' />
        </div>
        <WdcButton
          buttonText='submit form'
          buttonType='submit'
          className='mt-4'
        />
      </form>
    </div>
  )
}

export default FooterForm
